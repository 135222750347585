import ListTitle from '@aurora/shared-client/components/common/List/ListTitle';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import useCachedComponent from '@aurora/shared-client/components/useCachedComponent';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import type { ComponentProp } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import { isCustomPropValue } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import {
  ComponentMarkupLanguage,
  ComponentPageScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  createObjectByPath,
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { isCustomQuiltId } from '@aurora/shared-utils/helpers/quilts/QuiltHelper';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useMemo } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC, WidgetProps } from '../../common/Widget/types';
import EditContext from '../../context/EditContext/EditContext';
import PageEditorQuiltWrapperContext from '../../context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import QuiltWrapperWidgetLocationContext from '../../context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import type { ExternalComponentProps } from '../../external/components/ExternalComponent';
import useCustomComponentTranslation from '../../useCustomComponentTranslation';
import { WidgetVisibilityGroup } from '../../users/types';
import CustomComponentContent from '../CustomComponentContent/CustomComponentContent';
import localStyles from './CustomComponent.module.pcss';

const ExternalComponent = dynamic<ExternalComponentProps>(
  () => import('../../external/components/ExternalComponent')
);

export interface CustomComponentProps extends WidgetProps {
  /**
   * Specifies the title of the custom content widget.
   */
  title: string;
  /**
   * Whether to show title.
   */
  useTitle?: boolean;
  /**
   * Whether to use background.
   */
  useBackground?: boolean;
  /**
   * Widget to be made visible to anonymous users based on this value.
   */
  widgetVisibility?: WidgetVisibilityGroup;
  /**
   * Whether to use page width
   */
  usePageWidth?: boolean;
  /**
   * Background color of the custom component.
   */
  backgroundColor?: string;
  /**
   * Background color opacity of the custom component.
   */
  backgroundOpacity?: number;
}

export function getCustomFieldProps(
  customFields: Omit<
    CustomComponentProps,
    | 'widgetVisibility'
    | 'useTitle'
    | 'useBackground'
    | 'title'
    | 'lazyLoad'
    | 'usePageWidth'
    | 'backgroundColor'
    | 'backgroundOpacity'
  >
): Array<ComponentProp> {
  const customComponentProps: Array<ComponentProp> = [];
  Object.keys(customFields).forEach(customFieldName => {
    const customFieldValue = customFields[customFieldName];
    if (!customFieldValue || isCustomPropValue(customFieldValue)) {
      customComponentProps.push({
        name: customFieldName,
        value: customFieldValue ?? null
      });
    }
  });
  return customComponentProps;
}

const log = getLog(module);

const defaultProps: Partial<CustomComponentProps> = {
  useTitle: true,
  useBackground: true,
  widgetVisibility: WidgetVisibilityGroup.SIGNED_IN_OR_ANONYMOUS
};

export function getFinalProps(props: CustomComponentProps): CustomComponentProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

const componentPageScopeToPageIdMap: Record<ComponentPageScope, EndUserPages> = {
  [ComponentPageScope.Community]: EndUserPages.CommunityPage,
  [ComponentPageScope.Category]: EndUserPages.CategoryPage,
  [ComponentPageScope.Grouphub]: EndUserPages.GroupHubPage,
  [ComponentPageScope.User]: EndUserPages.UserPage,
  [ComponentPageScope.BlogBoard]: EndUserPages.BlogBoardPage,
  [ComponentPageScope.BlogMessage]: EndUserPages.BlogMessagePage,
  [ComponentPageScope.ForumBoard]: EndUserPages.ForumBoardPage,
  [ComponentPageScope.ForumMessage]: EndUserPages.ForumMessagePage,
  [ComponentPageScope.TkbBoard]: EndUserPages.TkbBoardPage,
  [ComponentPageScope.TkbMessage]: EndUserPages.TkbMessagePage
};

/**
 * Renders a custom (either a Text/HTML or Handlebars) component.
 *
 * @author Patricio Lugli, Doug Schroeder
 */
const CustomComponent: WidgetFC<CustomComponentProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps = getFinalProps(rest);
  const {
    title,
    useTitle,
    useBackground,
    widgetVisibility,
    usePageWidth,
    backgroundColor,
    backgroundOpacity,
    ...customFields
  } = finalProps;

  const customComponentProps = getCustomFieldProps(customFields);
  const cx = useClassNameMapper(localStyles);
  const { pageId } = useContext(PageContext);
  const {
    widgetData,
    setWidgetData,
    showEditControls: showQuiltEditControls
  } = useContext(EditContext);
  const { showEditControls: showQuiltWrapperEditControls } = useContext(
    PageEditorQuiltWrapperContext
  );
  const showEditControls = showQuiltEditControls || showQuiltWrapperEditControls;
  const { id: customComponentId } = useContext(WidgetContext);
  const quilt = useContext(QuiltContext);
  const { isAnonymous } = useRegistrationStatus();
  log.debug('Rendering custom component with id %s', customComponentId);
  const cachedComponent = useCachedComponent(customComponentId, false);
  const { data, loading, localOverride } = cachedComponent;
  const component = data?.component;
  const i18n = useCustomComponentTranslation(customComponentId, component?.template.texts ?? {});
  const { formatMessage, hasMessage } = i18n;
  const titlePlaceholder = hasMessage('title') ? formatMessage('title') : '';
  const headerTitle = title && title.length > 0 ? title : titlePlaceholder;
  const safeColor = useSafeColor();
  const quiltWrapperWidgetLocation = useContext(QuiltWrapperWidgetLocationContext);
  // The presence of the quilt wrapper widget location context indicates that the widget is inside a quilt wrapper.
  const inQuiltWrapper = quiltWrapperWidgetLocation;

  const shouldHide =
    !showEditControls &&
    ((widgetVisibility === WidgetVisibilityGroup.SIGNED_IN_ONLY && isAnonymous) ||
      (widgetVisibility === WidgetVisibilityGroup.ANONYMOUS_ONLY && !isAnonymous));

  const shouldRender = useMemo(() => {
    if (!loading) {
      if (localOverride) {
        return true;
      }
      let finalVisibility = !shouldHide && !!component;
      if (component) {
        const {
          properties: {
            config: { applicablePages }
          }
        } = component;

        const allowedPages = applicablePages.map(
          pageScope => componentPageScopeToPageIdMap[pageScope]
        );
        const isAllowedWidget =
          isCustomQuiltId(quilt?.id) || allowedPages?.length === 0 || allowedPages.includes(pageId);

        if (!isAllowedWidget) {
          log.error(
            `widget with id ${customComponentId} is not allowed in the page. Update the 'componentPageScope' property for the widget`
          );
        }
        finalVisibility = isAllowedWidget && finalVisibility;
      }
      return finalVisibility;
    }
    return false;
  }, [loading, localOverride, shouldHide, component, quilt, customComponentId, pageId]);

  useEffect(() => {
    if (!loading) {
      isVisible(shouldRender);
    }
  }, [loading, isVisible, shouldRender]);

  if (loading || !shouldRender) {
    return null;
  }

  if (component) {
    createObjectByPath(widgetData, customComponentId, component.id);
    setWidgetData(widgetData);
  } else {
    log.warn('No custom component data found for id %s', customComponentId);
    return null;
  }

  const markupLanguage = component?.template?.markupLanguage;
  const external = markupLanguage === ComponentMarkupLanguage.React;

  const backgroundColorStyles: CSSPropertiesWithVars = {
    '--lia-local-bg-color': backgroundColor
      ? safeColor(backgroundColor).alpha(backgroundOpacity).toString()
      : 'transparent'
  };

  const article = (
    <article
      className={cx('lia-article', {
        'lia-with-background': useBackground,
        'lia-quilt-wrapper-controls': showQuiltWrapperEditControls
      })}
      data-testid="CustomComponent"
    >
      {useTitle && headerTitle.length > 0 && (
        <header className={cx('lia-g-mb-25')}>
          <ListTitle as="h3" className={cx('h5 mb-0')}>
            {headerTitle}
          </ListTitle>
        </header>
      )}
      <section
        className={cx(
          {
            'lia-g-message-body': markupLanguage === ComponentMarkupLanguage.Html
          },
          finalProps.className
        )}
      >
        {external ? (
          <ExternalComponent
            id={customComponentId}
            localOverride={localOverride}
            customComponentProps={customComponentProps}
            i18n={i18n}
            isEditMode={showEditControls}
          />
        ) : (
          <CustomComponentContent
            customComponentProps={customComponentProps}
            cachedComponent={data}
          />
        )}
      </section>
    </article>
  );

  const articleWrapper =
    usePageWidth && inQuiltWrapper ? (
      <ThemedContainer className={cx('lia-themed-container')}>{article}</ThemedContainer>
    ) : (
      article
    );

  return (
    <EditableWidget<CustomComponentProps> props={finalProps}>
      {inQuiltWrapper ? (
        <div className={cx('lia-wrapper')} style={backgroundColorStyles}>
          {articleWrapper}
        </div>
      ) : (
        articleWrapper
      )}
    </EditableWidget>
  );
};

export default CustomComponent;
